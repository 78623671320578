<template>
  <div class="main" v-loading="loading">
    <div class="breadcrumb">
      <a @click="this.$router.push('/person/niche')" class="breadcrumb_item"
        >商机响应</a
      >
      <div class="breadcrumb_item_separator">
        <el-icon color="#5D5D5D" :size="14">
          <ArrowRightBold />
        </el-icon>
      </div>
      <span class="breadcrumb_item">商机详情</span>
    </div>
    <div class="niche_detail_content">
      <div v-for="item in options" :key="item.label" class="niche_detail_info_item">
        <span class="niche_detail_info_label">{{item.label}}</span>
        <span class="niche_detail_info_value">{{data[item.value] || '' }}</span>
      </div>
      <div class="niche_detail_handle">
        <div class="niche_detail_handle_type2" v-if="data.is_enrolled">已报名</div>
        <div class="niche_detail_handle_type1" @click="signUp" v-else>立即报名</div>
        <div class="niche_detail_handle_type3" @click="unInterested"  v-show="!data.is_enrolled && data.is_interested == 0">不感兴趣</div>
      </div>
      <div class="niche_detail_tips">
        温馨提示：报名后，平台会有专人联系您在资质认证中提交的联系人
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowRightBold } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import tracker from "@/common/tracker.js";
// is_enrolled 是否报名（0：否；1：是）
// is_interested 是否感兴趣（0：默认；1：感兴趣；2：不感兴趣）
export default {
  components: {
    ArrowRightBold,
  },
  data() {
    return {
      options: [
        {
          label: "项目名称：",
          value: "project_name",
        },
        {
          label: "品牌名称：",
          value: "brand",
        },
        {
          label: "项目阶段：",
          value: "project_phase_name",
        },
        {
          label: "项目预算：",
          value: "budget_range",
        },
        {
          label: "项目背景：",
          value:
            "project_bg",
        },
        {
          label: "目标人群：",
          value: "target_group",
        },
        {
          label: "合作形式：",
          value: "cooperation_mode",
        },
        {
          label: "授权周期：",
          value: "auth_period",
        },
        {
          label: "合作时间：",
          value: "cooperation_month",
        },
        {
          label: "传播渠道：",
          value: "channel",
        },
        {
          label: "备注：",
          value: "remark",
        },
        {
          label: "报名截止时间：",
          value: "deadline",
        },
      ],
      data:{},
      loading: true,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  methods:{
    signUp(){
      this.API.demand_enrol({id:this.id,opType:1},() => {
        ElMessage.success('报名成功！'),
        this.data.is_enrolled = 1
      })
    },
    unInterested(){
      this.API.demand_enrol({id:this.id,opType:2},() => {
        this.data.is_interested = 2
      })
      tracker({
        opType:20,
        ipId: this.id,
        url:this.$route.path
      });
    },
    async getDetail(){
      this.loading = true;
      await  this.API.demand_detail({id:this.id},(data) => {
        this.data = data.data
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="less" type="text/css" scoped="">
.main {
  flex: 1;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 25px;
}
.breadcrumb {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  .breadcrumb_item_separator {
    margin: 0 10px;
  }
  > a {
    color: #5d5d5d;
    &:hover {
      color: #ea5504;
    }
  }
  > span {
    color: #0b0b0b;
  }
}
.niche_detail_content{
  margin-top: 40px;
  .niche_detail_info_item{
      font-size: 14px;
      margin-bottom: 16px;
      display: flex;
    .niche_detail_info_label{
      width: 130px;
      display: inline-block;
      text-align: right;
      color: #2E3036;
      line-height: 22px;
    }
    .niche_detail_info_value{
      color: #888888;
      flex: 1;
      line-height: 22px;
    }
  }
  .niche_detail_handle{
    display: flex;
    padding-left: 60px;
    margin-top: 28px;
    >div{
      width: 136px;
      height: 42px;
      border-radius: 4px;
      border: 1px solid transparent;
      line-height: 42px;
      text-align: center;
      margin-right: 40px;
      font-size: 14px;
      cursor: pointer;
    }
    .niche_detail_handle_type1{
      border-color: #F5630B;
      background-color: #F5630B;
      color: #FFFFFF;
    }
    .niche_detail_handle_type2{
      border-color: #F4F4F4;
      background-color: #F4F4F4;
      color: #0B0B0B ;
    }
    .niche_detail_handle_type3{
      border-color: #F5630B;
      background-color: #fff;
      color: #EA5504 ;
    }
  }
  .niche_detail_tips{
    padding-left: 60px;
    margin-top: 16px;
    font-size: 14px;
    color: #888888;
    line-height: 22px
  }
}
</style>
